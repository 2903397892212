import { IMG, SPECIAL_DATA } from "constant";
import React from "react";

import SwiperCore, { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useState, useEffect } from "react";
import { useDeviceType } from "hooks/useMediaQuery";
import "swiper/css";
import "swiper/css/navigation";
import Styles from "./styles";

// img
import ChartImg from "resources/image/system/chart_img.png";
import SystemImg1 from "resources/image/system/system_img0.jpg";
import SystemImg3 from "resources/image/system/system_img2.jpg";
import SystemImg4 from "resources/image/system/system_img3.jpg";
import SystemImg5 from "resources/image/system/system_img4.jpg";
import SystemImg6 from "resources/image/system/system_img5.jpg";

const SpecialItem = ({ id, headText, subText, imgList }) => {
  return (
    <>
      <div className="item-wrap">
        <div className="count-num">{id}</div>
        <div className="head">
          <h3 className="heading">{headText}</h3>
          <h4 className="sub-text">{subText}</h4>
        </div>
      </div>

      {imgList && (
        <ul className="img-list">
          {imgList.map((imgItem) => {
            return (
              <li className="img-item" key={`${imgItem.id}-img`}>
                <img src={imgItem.src} alt={imgItem.alt} />
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
};

const IntroSpecial = () => {
  SwiperCore.use([Autoplay]);
  const deviceType = useDeviceType();
  const [swiper, setSwiper] = useState(null);
  const [active, setIndex] = useState(0);

  const handleSlideChange = (swiper) => {
    setIndex(swiper?.activeIndex);
  };

  const goToSlide = (index) => {
    if (swiper) {
      swiper.slideTo(index); // 특정 인덱스로 슬라이드 이동
      setIndex(index); // 상태 업데이트
    }
  };

  const [imgHover, setImgHover] = useState(null);

  const onHover = (idx) => {
    setImgHover(idx);
  };
  const onLeave = (idx) => {
    setImgHover(null);
  };

  return (
    <section className="section special full">
      <div className="special-wrap">
        <div className="special-banner">
          <div className="text-wrap">
            <h4>절박한 상황에 처한 의뢰인에게 </h4>
            <h3>
              신속한 <strong> 일상 복귀</strong>를<br />
              도와드리겠습니다.
            </h3>
            <h4 className="bottom">
              법무법인 온강을 선택해야 하는
              <span className="high-light">6가지 이유</span>
              반드시 읽어주세요.
            </h4>
          </div>
        </div>
        <div className="special-reason">
          <ul className="reason-list">
            {SPECIAL_DATA.map((item, idx) => {
              return (
                <li className="reason-item" onMouseEnter={() => onHover(idx)} onMouseLeave={() => onLeave(idx)}>
                  <SpecialItem id={item.id} headText={item.text} subText={item.subText} />
                  <img className="special-step-img" src={imgHover === idx ? item.hoverIgm : item.img} alt="" />
                </li>
              );
            })}
          </ul>
        </div>

        <Styles.SystemPage>
          <div className="system_inner">
            <h2 className="tit">
              온강만의 <span>프리미엄 시스템</span>
            </h2>
            <div className="chart_wrap">
              <img src={ChartImg} className="chart_img" alt="" />
              <ul className="index_list">
                <li className={`${active === 0 ? "active" : ""} a`}>
                  <i onClick={() => goToSlide(0)}>1</i>
                  <span>방문상담</span>
                </li>
                <li className={`${active === 1 ? "active" : ""} b`}>
                  <i onClick={() => goToSlide(1)}>2</i>
                  <span>
                    검사출신 대표변호사들의
                    <br className="mbBr" /> 사건 전략 회의
                  </span>
                </li>
                <li className={`${active === 2 ? "active" : ""} c`}>
                  <i onClick={() => goToSlide(2)}>3</i>
                  <span>시뮬레이션</span>
                </li>

                <li className={`${active === 3 ? "active" : ""} e`}>
                  <i onClick={() => goToSlide(3)}>4</i>
                  <span>조사 입회</span>
                </li>
                <li className={`${active === 4 ? "active" : ""} f`}>
                  <i onClick={() => goToSlide(4)}>5</i>
                  <span>재판 변론</span>
                </li>
              </ul>
            </div>
            <Swiper
              // modules={[Autoplay]}
              className="system_slider"
              slidesPerView={1}
              centeredSlides={false}
              autoHeight={true}
              // autoplay={{ delay: 2500, disableOnInteraction: false }}
              onSlideChange={handleSlideChange}
              onSwiper={(e) => {
                setSwiper(e);
              }}
              onInit={(swiper) => setIndex(swiper.activeIndex)}
            >
              <SwiperSlide>
                <div className="system_slide">
                  <img src={SystemImg1} className="slide_img" alt="" />
                  <div className="system_text">
                    <i className="num">1</i>
                    <p className="tit">
                      <span>방문상담</span>
                    </p>
                    <p className="dec">
                      범죄 분야별 검사출신 센터장이 직접 상담 진행하며, 사건 진단 후 <br className="pcBr" />
                      사건 해결에 필요한 주요 증거 확보 등 전략안에 대해 <b>1차 솔루션 제시</b>
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="system_slide">
                  <img src={SystemImg3} className="slide_img" alt="" />
                  <div className="system_text">
                    <i className="num">2</i>
                    <p className="tit">
                      <span>검사출신</span> 대표변호사들의 사건 전략 회의
                    </p>
                    <ul className="dec_list">
                      <li className="dec">
                        <b>경찰 단계인 경우</b>
                        <p>경찰에게 수사기법을 강의하는 검사출신 대표 변호사들이 무혐의 가능성을 진단하고 신속한 사건 종결 전략에 대해 논의</p>
                      </li>
                      <li className="dec">
                        <b>검찰 단계인 경우</b>
                        <p>검사출신 변호사를 비롯한 온강의 변호인단이 해당 사건의 불기소 가능성을 진단하고 사건 종결 전략에 대해 논의</p>
                      </li>
                      <li className="dec">
                        <b>재판 단계인 경우</b>
                        <p>사건 목표별로 감형 내지는 무죄 변론 전략에 대해 논의</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="system_slide">
                  <img src={SystemImg4} className="slide_img" alt="" />
                  <div className="system_text">
                    <i className="num">3</i>
                    <p className="tit">
                      <span>시뮬레이션</span>
                    </p>
                    <p className="dec">
                      경찰 및 검찰 조사와 유사한 환경에서 수사 상황을 대비한 <br className="pcBr" />
                      <b>「실전 대응 시뮬레이션」</b> 실시
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="system_slide">
                  <img src={SystemImg5} className="slide_img" alt="" />
                  <div className="system_text">
                    <i className="num">4</i>
                    <p className="tit">
                      <span>조사입회</span>
                    </p>
                    <p className="dec">
                      경찰, 검찰 조사 단계에 <b>온강의 변호사가 동행</b>하여 사전에 준비한 대로
                      <br className="pcBr" />
                      진술할 수 있도록 조력하고,&nbsp;
                      <br className="mbBr" />
                      수사 기관의 수사 진척도 등을 파악해 <br className="pcBr" />
                      <b>의뢰인에게 유리한 흐름</b>을 만들 수 있도록 경찰 및 검찰에 <br className="pcBr" />
                      변호인 의견서를 제출
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="system_slide">
                  <img src={SystemImg6} className="slide_img" alt="" />
                  <div className="system_text">
                    <i className="num">5</i>
                    <p className="tit">
                      <span>재판 변론</span>
                    </p>
                    <p className="dec">
                      직접 재판을 이끌던 공판검사 출신 대표변호사가 증인신문, 변론방향 등 <br className="pcBr" />
                      재판 전략을 수립하여 <b>판사를 설득할 수 있는 변곡점</b>을 만들어 낼 수 있도록 최선을 다함
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </Styles.SystemPage>
      </div>
    </section>
  );
};

export default IntroSpecial;
