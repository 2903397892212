import { useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import Styles from "./styles";

// img
import IconLogoWh from "resources/image/icon/icon_logo_wh.svg";
import IconLogo from "resources/image/icon/icon_logo.svg";
import IconCounsel from "resources/image/icon/icon_counsel.svg";

function Header() {
  const ref = useRef();
  const navigate = useNavigate();
  const [scroll, setScroll] = useState(false);
  const [on, setOn] = useState(false);

  useEffect(() => {
    setScroll(window.location.pathname.includes("search"));
    document.querySelector("#header").addEventListener("mouseenter", handle);
    document.querySelector("#header").addEventListener("mouseleave", handleOut);
    window.addEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    if (window.location.pathname.includes("search")) return;
    setScroll(window.scrollY > 0);
  };
  const handle = (e) => {
    setOn(true);
  };
  const handleOut = (e) => {
    setOn(false);
  };

  return (
    <Styles.Header id="header" ref={ref} className={[scroll || on ? "on" : "", on ? "open" : ""]}>
      <header className="header">
        <h1
          className="logo"
          onClick={() => {
            navigate("/");
          }}
        >
          <img src={scroll || on ? IconLogo : IconLogoWh} alt="logo" />
        </h1>
        <ul className="gnb">
          <li>
            <span
              onClick={() => {
                navigate("/intro/introduce");
              }}
            >
              온강의 모든 것
            </span>
            {on && (
              <ul className="lnb">
                <li
                  onClick={() => {
                    navigate("/intro/introduce");
                  }}
                >
                  <span>온강 소개</span>
                </li>
                <li
                  onClick={() => {
                    navigate("/intro/special");
                  }}
                >
                  <span>온강의 특별함</span>
                </li>
                <li
                  onClick={() => {
                    navigate("/intro/expert");
                  }}
                >
                  <span>전문가 소개</span>
                </li>
                <li
                  onClick={() => {
                    navigate("/intro/news");
                  }}
                >
                  <span>언론보도</span>
                </li>
                <li
                  onClick={() => {
                    navigate("/intro/map");
                  }}
                >
                  <span>오시는 길</span>
                </li>
              </ul>
            )}
          </li>
          <li>
            <span
              onClick={() => {
                navigate("/example");
              }}
            >
              성공사례
            </span>
            {on && (
              <ul className="lnb">
                <li
                  onClick={() => {
                    navigate("/example");
                  }}
                >
                  <span>성공사례</span>
                </li>
              </ul>
            )}
          </li>
          <li>
            <span
              onClick={() => {
                navigate("/story/review");
              }}
            >
              온강 이야기
            </span>
            {on && (
              <ul className="lnb">
                <li
                  onClick={() => {
                    navigate("/story/review");
                  }}
                >
                  <span>의뢰인 후기</span>
                </li>
                <li
                  onClick={() => {
                    navigate("/story/youtube");
                  }}
                >
                  <span>유튜브</span>
                </li>
                <li
                  onClick={() => {
                    navigate("/story/column");
                  }}
                >
                  <span>컬럼</span>
                </li>
              </ul>
            )}
          </li>
          <li>
            <span
              onClick={() => {
                navigate("/counsel");
              }}
            >
              상담신청
            </span>
            {on && (
              <ul className="lnb">
                <li
                  onClick={() => {
                    navigate("/counsel");
                  }}
                >
                  <span>1:1 비밀상담</span>
                </li>
              </ul>
            )}
          </li>
        </ul>
        <button
          className="btn_counsel"
          onClick={() => {
            navigate("/counsel");
          }}
        >
          <img src={IconCounsel} alt="logo" />
          <span>상담신청</span>
        </button>
      </header>
    </Styles.Header>
  );
}

export { Header };

export default Header;
