import {
  DefaultModal,
  ToastModal,
  TermsModal,
  ArticleModal
} from "components/modal";
import { useModals } from "hooks";
import { useEffect } from "react";


export default function ModalPage() {
  const modalOption = useModals();

  useEffect(()=>{
    const body = document.body
    const html = document.querySelector('html')
    if(modalOption.defaultModal.show ||
      modalOption.toastModal.show ||
      modalOption.termsModal.show ||
      modalOption.articleModal.show){
        body.className = "hidden";
        html.className = "hidden";
      }else{
        body.classList.remove('hidden');
        html.classList.remove('hidden');
      }
  },[modalOption])

  return (
    <>
      {
        //기본 모달
        modalOption.defaultModal.show ? (
          <DefaultModal
            modalOption={modalOption.defaultModal}
            modalClose={() => {
              modalOption.setDefaultModal((e) => {
                e.show = false;
                e.title = '';
                e.dec = '';
                return { ...e };
              });
            }}
          />
        ) : null
      }
      {
        //토스트 메세지 모달
        modalOption.toastModal.show ? (
          <ToastModal
            modalOption={modalOption.toastModal}
            modalClose={() => {
              modalOption.setToastModal((e) => {
                e.show = false;
                return { ...e };
              });
            }}
          />
        ) : null
      }
      {
        //약관 모달
        modalOption.termsModal.show ? (
          <TermsModal
            modalOption={modalOption.termsModal}
            modalClose={() => {
              modalOption.setTermsModal((e) => {
                e.show = false;
                return { ...e };
              });
            }}
          />
        ) : null
      }
      {
        //조문 상세내용 모달
        modalOption.articleModal.show ? (
          <ArticleModal
            modalOption={modalOption.articleModal}
            modalClose={() => {
              modalOption.setArticleModal((e) => {
                e.show = false;
                return { ...e };
              });
            }}
          />
        ) : null
      }
    </>
  );
}
