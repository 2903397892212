import styled from "styled-components";

const MainLawyerList = styled.div`
  position: relative;
  width: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
  margin: 0 auto;
  & h2 {
    font-size: 56px;
    font-weight: 800;
    letter-spacing: -0.5px;
    text-align: center;
    & span {
      font-weight: 800;
      color: #DA250B;
      margin-left: 4px;
    }
  }
  & p {
    font-size: 26px;
    font-weight: 500;
    line-height: 36px;
    text-align: center;
    margin-top: 20px;
  }
  & .slider {
    width: 1260px;
    margin: 20px auto 0;
    & .swiper-slide {
      width: 440px; min-height: 622px;
      & .slide_box {
        position: absolute;
        top: 50%; left: 50%;
        width: 440px;
        border-radius: 5px;
        background: #E4E4E4;
        transform: translate(-50%,-50%);
        transition: 0.8s;
        padding-bottom: 35px;
        & .slide_img {
          width: 292px; height: 437px;
          margin: 34px auto;
          object-fit: contain;
          filter: grayscale(100%);
          opacity: 0.5;
        }
        & .name {
          display: none;
          align-items: center;
          justify-content: center;
          font-size: 26px;
          color: #fff;
          & b {
            font-weight: 800;
            margin-right: 6px;
          }
          & .icon_img {width: 31px; margin-right: 5px;}
        }
        & .detail_btn {
          display: none;
          align-items: center;
          justify-content: space-between;
          width: 174px; height: 45px;
          border: 1px solid #fff;
          border-radius: 45px;
          font-size: 18px;
          font-weight: bold;
          color: #fff;
          padding: 0 30px;
          margin: 10px auto 0;
          & img {width: 18px; height: 18px;}
        }
      }
      &.swiper-slide-active {
        height: 622px;
        margin-top: 40px;
        & .slide_box {
          width: 440px; height: 622px;
          background: linear-gradient(180deg, #B21600 -7.21%, #222 119.12%);
          & .slide_img {
            filter: none;
            opacity: 1;
          }
          & .name {display: flex;}
          & .detail_btn {display: flex;}
        }
      }
      &.swiper-slide-next, &.swiper-slide-prev {
        & .slide_box {
          background: #C4C4C4;
        }
      }
    }
  }
  & .slide_btn {
    position: absolute;
    top: 550px;
    width: 70px; height: 70px;
    z-index: 1;
    &.btn_prev {
      left: 50%;
      margin-left: -750px;
    }
    &.btn_next {
      right: 50%;
      margin-right: -750px;
    }
  }

  /* 테블릿 */
  @media (max-width: 1520px) {
    padding-top: calc(100vw*(100/1520));
    padding-bottom: calc(100vw*(100/1520));
    & h2 {
      font-size: calc(100vw*(56/1520));
      /* & span {
        margin-left: calc(100vw*(8/1520));
      } */
    }
    & p {
      font-size: calc(100vw*(26/1520));
      line-height: calc(100vw*(36/1520));
      margin-top: calc(100vw*(20/1520));
    }
    & .slider {
      width: calc(100vw*(1260/1520));
      margin: calc(100vw*(20/1520)) auto 0;
      & .swiper-slide {
        width: calc(100vw*(440/1520)); min-height: calc(100vw*(622/1520));
        & .slide_box {
          width: calc(100vw*(440/1520));
          border-radius: calc(100vw*(5/1520));
          padding-bottom: calc(100vw*(35/1520));
          & .slide_img {
            width: calc(100vw*(292/1520)); height: calc(100vw*(437/1520));
            margin: calc(100vw*(34/1520)) auto;
          }
          & .name {
            font-size: calc(100vw*(26/1520));
            & b {
              margin-right: calc(100vw*(6/1520));
            }
            & .icon_img {width: calc(100vw*(31/1520)); margin-right: calc(100vw*(5/1520));}
          }
          & .detail_btn {
            width: calc(100vw*(174/1520)); height: calc(100vw*(45/1520));
            border-radius: calc(100vw*(45/1520));
            font-size: calc(100vw*(18/1520));
            padding: 0 calc(100vw*(30/1520));
            margin: calc(100vw*(10/1520)) auto 0;
            & img {width: calc(100vw*(18/1520)); height: calc(100vw*(18/1520));}
          }
        }
        &.swiper-slide-active {
          height: calc(100vw*(622/1520));
          margin-top: calc(100vw*(40/1520));
          & .slide_box {
            width: calc(100vw*(440/1520)); height: calc(100vw*(622/1520));
          }
        }
      }
    }
    & .slide_btn {
      top: calc(100vw*(550/1520));
      width: calc(100vw*(70/1520)); height: calc(100vw*(70/1520));
      &.btn_prev {
        margin-left: calc(100vw*(-750/1520));
      }
      &.btn_next {
        margin-right: calc(100vw*(-750/1520));
      }
    }
  }

  /* 모바일 */
  @media (max-width: 1024px) {
    padding-top: calc(100vw*(80/375));
    padding-bottom: calc(100vw*(100/375));
    & h2 {
      font-size: calc(100vw*(36/375));
      line-height: calc(100vw*(43/375));
      /* & span {
        margin-left: calc(100vw*(-2/375));
      } */
    }
    & p {
      font-size: calc(100vw*(18/375));
      line-height: calc(100vw*(25/375));
      margin-top: calc(100vw*(15/375));
      word-break: keep-all;
      padding: 0 calc(100vw*(36/375));
      letter-spacing: -0.5px;
      & br {display: none;}
    }
    & .slider {
      width: 100%;
      margin: calc(100vw*(30/375)) auto 0;
      & .swiper-slide {
        width: calc(100vw*(277/375)); min-height: calc(100vw*(391/375));
        & .slide_box {
          width: calc(100vw*(277/375));
          border-radius: calc(100vw*(5/375));
          padding-bottom: calc(100vw*(35/375));
          & .slide_img {
            width: calc(100vw*(184/375)); height: calc(100vw*(275/375));
            margin: calc(100vw*(16/375)) auto calc(100vw*(12/375));
            object-fit: cover;
          }
          & .name {
            font-size: calc(100vw*(18/375));
            margin-top: 0;
            & b {
              margin-right: calc(100vw*(4/375));
            }
            & .icon_img {width: calc(100vw*(17/375)); margin-right: calc(100vw*(5/375));}
          }
          & .detail_btn {
            width: calc(100vw*(132/375)); height: calc(100vw*(39/375));
            border-radius: calc(100vw*(40/375));
            font-size: calc(100vw*(16/375));
            padding: 0 calc(100vw*(20/375));
            margin: calc(100vw*(5/375)) auto 0;
            & img {width: calc(100vw*(18/375)); height: calc(100vw*(18/375));}
          }
        }
        &.swiper-slide-active {
          height: calc(100vw*(391/375));
          margin-top: 0;
          & .slide_box {
            width: calc(100vw*(277/375)); height: calc(100vw*(391/375));
          }
        }
      }
    }
    & .slide_btn {
      top: auto; bottom: calc(100vw*(290/375));
      width: calc(100vw*(34/375)); height: calc(100vw*(34/375));
      &.btn_prev {
        margin-left: calc(100vw*(-170/375));
      }
      &.btn_next {
        margin-right: calc(100vw*(-170/375));
      }
    }
  }
`;

export { MainLawyerList };

export default {
  MainLawyerList,
};
