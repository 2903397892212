import question from "./question";
import board from "./board";
import category from "./category";
import site from "./site";
import inquiry from "./inquiry";

export default {
  question,
  board,
  category,
  site,
  inquiry,
};

export { question, board, category, site, inquiry };
