import { atom } from "jotai";

const defaultModalAtom = atom({ show: false, zIndex: 20 });
const toastModalAtom = atom({ show: false, zIndex: 20 });
const termsModalAtom = atom({ show: false, zIndex: 20 });
const articleModalAtom = atom({ show: false, zIndex: 20 });

export {
  defaultModalAtom,
  toastModalAtom,
  termsModalAtom,
  articleModalAtom
};

export default {
  defaultModalAtom,
  toastModalAtom,
  termsModalAtom,
  articleModalAtom
};
