import styled, { css } from 'styled-components';

// img
import bannerBg from "resources/image/main/main_banner_bg.jpg";
import MbbannerBg from "resources/image/main/mb_main_banner_bg.jpg";

const MainBanner = styled.div`
  position: relative;
  width: 100%; height: 980px;
  background: #000 url(${bannerBg}) no-repeat center /1920px;
  overflow: hidden;
  &.active {
    & .bnnr_tit p {
      animation: fadeUp 0.8s ease-in forwards;
    }
    & .bnnr_tit h2 {
      animation: fadeUp 0.8s ease-in forwards;
    }
  }
  & .bnnr_inner {
    position: relative;
    height: 100%;
    padding-top: 274px;
    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 0; bottom: 0;
      width: 100%; height: 540px;
      background: linear-gradient(0deg, #000 38%, rgba(0, 0, 0, 0.00) 100%);
      z-index: 1;
    }
    & .bnnr_tit {
      display: flex;
      align-items: center;
      flex-direction: column-reverse;
      position: relative;
      width: 1260px; height: 100%;
      color: #fff;
      margin: 0 auto;
      padding-bottom: 90px;
      & p {
        position: relative;
        font-size: 36px;
        font-weight: 200;
        text-align: center;
        opacity: 0;
        transition: 0.8s;
        z-index: 2;
        & b {
          font-weight: bold;
          color: #DA250B;
        }
      }
      & h2 {
        position: relative;
        font-size: 90px;
        line-height: 107px;
        font-weight: 200;
        text-align: center;
        margin-top: 13px;
        opacity: 0;
        transition: 0.8s;
        font-weight: bold;
        z-index: 2;
      }
      & .line {
        position: absolute;
        width: 100%; height: 100%;
        background-color: #000;
        box-shadow: 0 0 130px 20px #DA250B;
        margin-bottom: -90px;
        & i {
          position: absolute;
        }
        & i.top {
          top: 0; left: 0;
          display: block;
          width: 0; height: 12px;
          background-color: #DA250B;
          animation: row 1.2s ease-in forwards;
        }
        & i.right {
          top: 0; right: 0;
          display: block;
          width: 12px; height: 0;
          background-color: #DA250B;
          animation: column 1.2s ease-in 0.5s forwards;
        }
        & i.left {
          bottom: 0; left: 0;
          display: block;
          width: 12px; height: 0;
          background-color: #DA250B;
          animation: column 1.2s ease-in forwards;
        }
      }
    }
  }
  & .bnnr_img {
    position: absolute; bottom: 0; left: 50%;
    display: block;
    width: 1213px;
    transform: translateX(-50%);
  }
  @keyframes rotate {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes fadeUp {
    0% {
      opacity: 0;
      transform: translateY(30px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @keyframes row {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
  @keyframes column {
    0% {
      height: 0;
    }
    100% {
      height: 100%;
    }
  }

  /* 테블릿 */
  @media (max-width: 1520px) {
    height: calc(100vw*(980/1520));
    & .bnnr_inner {
      padding-top: calc(100vw*(274/1520));
      &::after {
        height: calc(100vw*(540/1520));
      }
      & .bnnr_tit {
        width: calc(100vw*(1260/1520));
        padding-bottom: calc(100vw*(90/1520));
        & p {
          font-size: calc(100vw*(36/1520));
        }
        & h2 {
          font-size: calc(100vw*(90/1520));
          line-height: calc(100vw*(107/1520));
          margin-top: calc(100vw*(13/1520));
        }
        & .line {
          box-shadow: 0 0 calc(100vw*(130/1520)) calc(100vw*(20/1520)) #DA250B;
          margin-bottom: calc(100vw*(-90/1520));
        }
      }
    }
    & .bnnr_img {
      position: absolute; bottom: 0; left: 50%;
      display: block;
      width: calc(100vw*(1213/1520));
      transform: translateX(-50%);
    }
  }

  /* 모바일 */
  @media (max-width: 1024px) {
    height: calc(100vw*(440/375));
    background-image: url(${MbbannerBg});
    background-size: cover;
    & .bnnr_inner {
      padding-top: calc(100vw*(108/375));
      &::after {
        height: calc(100vw*(282/375));
        background: linear-gradient(0deg, #000 66%, rgba(0, 0, 0, 0.00) 100%);
      }
      & .bnnr_tit {
        width: calc(100vw*(335/375));
        line-height: calc(100vw*(27/375));
        padding-bottom: calc(100vw*(60/375));
        & p {
          font-size: calc(100vw*(20/375));
          & span {display: block;}
        }
        & h2 {
          font-size: calc(100vw*(46/375));
          line-height: calc(100vw*(55/375));
          margin-top: calc(100vw*(10/375));
        }
        & .line {
          background-color: #000;
          box-shadow: 0 0 130px 20px #DA250B;
          margin-bottom: calc(100vw*(-60/375));
          & i.top {
            height: calc(100vw*(5/375));
          }
          & i.right {
            width: calc(100vw*(5/375));
          }
          & i.left {
            width: calc(100vw*(5/375));
          }
        }
      }
    }
    & .bnnr_img {
      bottom: calc(100vw*(74/375));
      width: calc(100vw*(327/375));
    }
  }
`;




export {
  MainBanner
}

export default {
  MainBanner
}