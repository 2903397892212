import { MainBanner,
  MainExample,
  MainProcess,
  MainLawyerList,
  MainMonthly,
  MainReview,
  MainYoutube,
  MainBroadcast,
  MainSearch,
  MainCounsel,
  MainCenter,
  MainDirection,
} from "./components";

export default function MainPage() {
  return (
    <>
      <MainBanner />
      <MainSearch />
      <MainProcess />
      <MainLawyerList />
      <MainMonthly />
      <MainExample />
      <MainYoutube />
      <MainReview />
      <MainBroadcast />
      <MainCounsel />
      <MainCenter />
      <MainDirection />
    </>
  );
}
