import Styles from './styles';
import { useNavigate, useParams } from "react-router-dom";
import { Banner } from "components/layout";
import { useDeviceType } from "hooks/useMediaQuery";

// img
import BannerBg from "resources/image/example/example_banner_bg.jpg";
import MbBannerBg from "resources/image/example/mb_example_banner_bg.jpg";
import { useBoard } from 'hooks';
import utils from 'utils';

export default function ExampleDetailPage() {
  const navigate = useNavigate();
  const deviceType = useDeviceType();
  const param = useParams();
  const boardInfo = useBoard({
    detailOption: { id : param.id }
  });

  const detail = boardInfo.detailQuery?.data?.data || {};

  return (
    <>
      <Banner
        title={"성공사례"}
        sub={"직접 수사하고 재판한 경험을 기반으로,<br class='pcBr'/><br class='mbBr'/>의뢰인의 빠른 일상 복귀를 위해 성실히 <br class='mbBr'/>변론한 결과입니다."}
        img={deviceType === 'pc' ? BannerBg : MbBannerBg}
      />
      <Styles.ExampleDetail>
        <div className="detail_wrap">
          <div className="detail_inner">
            <div className="title_box">
              <span className="tag">{detail.keyword}</span>
              <p className="detail_tit">{detail.title}</p>
              <div className="date_box">
                <b>종결일</b>
                <span className="date">{utils.date.getDateType(detail.created_at)}</span>
                <span className="group">{detail.subtitle}</span>
              </div>
            </div>
            <div className="detail_content">
              {/* s: 개발시 삭제 디자인 확인용 코드 */}
              <div  className="editor ck-content" style={{minHeight:400, }} dangerouslySetInnerHTML={{__html : detail?.description}}>
                {/* <p style={{fontSize:"34px", color: "#C4C4C4", textAlign:"center"}}>edite</p>
                <p dangerouslySetInnerHTML={{__html : detail?.description}}></p> */}
              </div>
              {/* e: 개발시 삭제 디자인 확인용 코드 */}
            </div>
          </div>
          <button className="back_btn" onClick={() => { window.history.back(); }}>목록으로 돌아가기</button>
        </div>
      </Styles.ExampleDetail>
    </>
    
  );
}
