import Styles from './styles';
import { useEffect, useState } from "react";
import { IMAGEPATH } from "service/connector";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";

// swiper
import "swiper/css";
import 'swiper/css/effect-coverflow';
import SwiperCore, { EffectCoverflow, Autoplay} from "swiper";

// img
import IconPlus from "resources/image/icon/icon_plus.svg";
import IconLogo from "resources/image/icon/white-logo.svg";
import SlideImg from "resources/image/main/lawyer_slide_img_01.png";
import IconNext from "resources/image/icon/icon_next.svg";
import IconPrev from "resources/image/icon/icon_prev.svg";
import useSite from "hooks/useSite";

function MainLawyerList() {
  const [swiper, setSwiper] = useState(null);
  const navigate = useNavigate();

  const siteInfo = useSite({
    listOption: {
      board_type: 1,
      content_type: 1,
      type: 1,
    },
  });

  const list = siteInfo.listQuery?.data?.list || [];

  const handlePrev = () => {
    swiper?.slidePrev();
  };

  const handleNext = () => {
    swiper?.slideNext();
  };
  
  useEffect(()=>{
    SwiperCore.use(EffectCoverflow);
    SwiperCore.use(Autoplay);
  },[])

  return (
    <Styles.MainLawyerList>
      <h2>온강의 <span>형사 전문</span> <br className="mbBr"/>변호인단</h2>
      <p>직접 수사재판한 검사출신 변호사들이 함께 연구하여 <br/>
      수사기관이 납득할만한 진술방향과 변론전략을 제시합니다.</p>
    {
      list.length > 0 &&
          <Swiper
            modules={[Autoplay]}
            effect="coverflow"
            centeredSlides={true}
            slidesPerView="auto"
            loop={true} 
            loopFillGroupWithBlank={true}
            speed={1500}
            autoplay={{ delay: 1500, disableOnInteraction: false }}
            coverflowEffect={{
              rotate: 0, // 회전 없음
              stretch: 0, // 슬라이드 간 간격
              depth: 440, // 비활성 슬라이드는 더 멀리
              modifier: 1, // 활성 슬라이드를 크게 표시하는 효과
              slideShadows: false // 그림자 제거
            }}
            className="slider"
            onSwiper={(e) => {setSwiper(e)}}
          >
            {list?.map((item, index) => {
              return (
                <SwiperSlide key={"exampleList" + index}>
                  <div className="slide_box">
                    <img src={IMAGEPATH + item.image} className="slide_img" alt='' />
                    <p className="name">
                      {
                        item.extra_image && <img src={IMAGEPATH + item.extra_image} className="icon_img" alt="" />
                      }
                      {item.subtitle && <b>{item.subtitle} </b>}
                      {item.title} 변호사
                    </p>
                    <button className="detail_btn" onClick={()=>{ navigate("intro/expert/detail/" + item.id);}}>약력 보기<img src={IconPlus} alt="detail view" /></button>
                  </div>
                </SwiperSlide>
              )
            })}
          </Swiper>
      }
      <button className='slide_btn btn_prev' onClick={handlePrev}>
        <img src={IconPrev} alt="슬라이드 이전" />
      </button>
      <button className='slide_btn btn_next' onClick={handleNext}>
        <img src={IconNext} alt="슬라이드 다음" />
      </button>
    </Styles.MainLawyerList>
  );
}

export default MainLawyerList;