import { useState } from "react";
import Styles from './styles';

function MainCenter() {
  const [hover, setHover] = useState(null);
  const centerList = [
    {
      title : '성범죄 전담센터',
      link : 'https://onganglaw.co.kr/성범죄'
    },
    {
      title : '마약 전담센터', 
      link : 'https://ongangdrug.co.kr/'
    },
    {
      title : '음주운전 전담센터', 
      link : 'http://drivecenter.co.kr/'
    },
    {
      title : '외국인 전담센터',
    },
    {
      title : '군형사 전담센터', 
    },
    {
      title : '경제센터 전담센터',
    }
  ]

  const handleMouseOver = (index) => {
    setHover(index);
  };
  
  const handleMouseLeave = () => {
    setHover(null);
  };

  return (
    <Styles.MainCenter>
      <div className="center_inner">
        <h2 className="tit">법무법인 온강의 <strong>분야별 전문센터</strong></h2>
        <ul className={`center_list hover${hover}`}>
          {
            centerList.map((value,index)=>{
              return <li onClick={()=>{ if(value.link){ window.open(value.link); }else{alert('사이트 준비중입니다.')}}} onMouseOver={() => handleMouseOver(index)} onMouseLeave={handleMouseLeave}>{value.title}</li>
            })
          }
        </ul>
      </div>
    </Styles.MainCenter>
  );
}

export default MainCenter;